/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

import {tns} from 'tiny-slider/src/tiny-slider';
import Player from '@vimeo/player';
import './bootstrap/collapse';
import './bootstrap/popover';
import './bootstrap/modal';

(function ($) {
  function welcomeVideo() {
    var iframe = document.getElementById('welcomeVid');

    function initPlayer(action) {
      setTimeout(function () {
        if (iframe.classList.contains('lazyloaded')) {
          var player = new Player(iframe);

          action === 'play' ? player.play() : player.pause();
        }
      }, 1000);
    }

    $('.modal').on('hidden.bs.modal', function () {
      initPlayer('pause');
    });

    $('.modal').on('shown.bs.modal', function () {
      initPlayer('play');
    });
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Alliance = {
    // All pages
    common: {
      init: function () {
        /*
         * Replace the asterisk with required text on enquire form
         */
        $('.enquire-form span.gfield_required').html(function (index, text) {
          return text.replace('*', '(Required)');
        });

        // FAQ's are part of ACF blocks
        $('.faqs__heading').each(function () {
          var $this = $(this);

          // create unique id for a11y relationship

          var id = 'collapsible-' + $this.index();

          // wrap the content and make it focusable

          $this
            .parent()
            .nextUntil('h3')
            .wrapAll('<div id="' + id + '" aria-hidden="true">');
          var panel = $this.parent().next();

          // Add the button inside the <h3> so both the heading and button semantics are read

          $this.wrapInner(
            '<button aria-expanded="false" aria-controls="' + id + '">'
          );
          var button = $this.children('button');

          // Toggle the state properties

          button.on('click', function () {
            var state =
              $(this).attr('aria-expanded') === 'false' ? true : false;
            $(this).attr('aria-expanded', state);
            panel.attr('aria-hidden', !state);
          });
        });

        const slider = () =>
          tns({
            container: '.achievement_customer__inner',
            items: 1,
            nav: false,
            controlsPosition: 'bottom',
            controlsText: ['Previous', 'Next'],
          });

        if ($('.achievement_customer__inner').length) {
          slider();
        }

        const bannerInner = $('.banner-inner');
        const bannerHeight = bannerInner.innerHeight();
        let windowPosition = 0;

        function handleScroll() {
          // Banner is only fixed if we have a hero so
          // we don't need to adjust background opacity.
          if ($('body').hasClass('no-hero')) {
            return;
          }

          $(window).scroll(function () {
            windowPosition = $(window).scrollTop();

            const opacity =
              (1 - (bannerHeight - windowPosition) / bannerHeight) * 0.8 + 0.2;

            if (windowPosition >= 0) {
              bannerInner.css({
                'background-color': `rgba(26,47,73,${
                  opacity < 1 ? opacity.toFixed(2) : 1
                })`,
              });
            }
          });
        }

        function mediaSize() {
          if (window.matchMedia('(min-width: 860px)').matches) {
            $(window).bind('scroll', handleScroll());
          } else {
            $(window).unbind('scroll', handleScroll());
          }
        }

        function mobileMegaMenu() {
          $('.mega-menu-column-title').each(function (index) {
            const $this = $(this);
            const id = 'collapsible-' + index;

            const wrapperClassName = index === 0 ? 'collapse show' : 'collapse';
            const buttonClassName = index === 0 ? '' : 'collapsed';

            $this
              .next()
              .wrapAll(
                '<div class="' +
                wrapperClassName +
                '" id="collapse-' +
                id +
                '">'
              );

            $this.wrapInner(
              '<button class="' +
              buttonClassName +
              '" type="button" data-toggle="collapse" data-target="#collapse-' +
              id +
              '" aria-controls="collapse-' +
              id +
              '">'
            );
          });
        }

        if (window.matchMedia('(max-width: 859px)').matches) {
          mobileMegaMenu();
        }

        // Initial call.
        mediaSize();
        // Check on resize.
        window.addEventListener('resize', mediaSize, false);
      },
      finalize: function () {
        // Check if there is a form on the page, if not change the source
        // of the Get in touch navigation button to point to contact form.
        // Global WPURLS defined in setup.php
        if (!$('#form').length || window.matchMedia('(max-width: 859px)').matches) {
          $('.top-nav-cta a[href="#form"]').attr(
            'href',
            //eslint-disable-next-line
            WPURLS.siteurl + '/contact/'
          );
        }

        $(document).mouseup(function (e) {
          const container = $('.mega-menu');

          if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.collapse('hide');
          }
        });

        $(document).mouseup(function (e) {
          const container = $('.our-process-menu');

          if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.collapse('hide');
          }
        });

        $('[data-toggle="popover"]').popover();
      },
    },
    home: {
      init: function () {
        welcomeVideo();
      },
    },
    body_our_work: {
      init: function () {
        const bannerInner = $('.banner-inner');
        let bannerHeight = bannerInner.innerHeight();

        const options = {
          root: null,
          rootMargin: `-${bannerHeight}px`,
          threshold: 0.5,
        };
        const projectNav = $('.project-nav');
        const handleThresholdCrossed = entries => {
          if (entries[0].isIntersecting) {
            projectNav.removeClass('project-nav--fixed');
            projectNav.css({top: 0});
            $('.wrap').css({marginTop: 0});
          } else {
            projectNav.addClass('project-nav--fixed');
            projectNav.css({top: bannerHeight});
            $('.wrap').css({marginTop: `${projectNav.innerHeight()}px`});
          }
        };

        function updateBannerHeight() {
          if (projectNav.hasClass('project-nav--fixed')) {
            bannerHeight = bannerInner.innerHeight();
            projectNav.css({top: bannerHeight});
          }
        }

        window.addEventListener('resize', updateBannerHeight, false);

        const target = document.getElementById('observer-sentinel');
        const observer = new IntersectionObserver(
          handleThresholdCrossed,
          options
        );
        observer.observe(target);

        /**
         * Jump through hoops to get offsets happening when you arrive from another page
         */
        function hash_scroll() {
          function scroll_to() {
            const target = location.hash;

            $('html, body').animate(
              {
                scrollTop:
                  $(target).offset().top -
                  projectNav.innerHeight() -
                  bannerHeight,
              },
              500,
              function () {
                location.hash = target;
              }
            );
          }

          const scroll_button = $('.project-nav a');

          // If we are clicking the button
          // Just to a normal scroll to minus the fixed header elements
          $(scroll_button).click(function (e) {
            e.preventDefault();

            const hash = $(this).attr('href');

            $('html, body').scrollTop(
              $(hash).offset().top - projectNav.innerHeight() - bannerHeight
            );
          });

          // If we are coming from another page
          // scroll to the top
          // then run the function that animates
          // to the element.
          if (location.hash) {
            setTimeout(function () {
              $('html, body').scrollTop(0);
              scroll_to();
            }, 0);
          }
        }

        hash_scroll();
      },
    },
    body_careers: {
      init: function () {
        const videoLink = $('#video-link');
        const videoEmbedSrc = videoLink.data('video');
        const videoImg = $('#video-link img');
        const videoIframe = `<iframe width="800" height="450" src="${videoEmbedSrc}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

        $(videoLink).on('click', function (e) {
          $(videoLink).after(videoIframe);
          $(videoImg).hide();
          e.preventDefault();
        });
      },
    },
    body_about: {
      init: function () {
        $('.timeline_scroller').animate({
          scrollLeft: 5140,
        });
      },
    },
    body_landing_general: {
      init: function () {
        welcomeVideo();
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  const UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Alliance;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
